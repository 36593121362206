









































import { Component, Vue } from 'vue-property-decorator';
import SuccessfullyRecoveredViewModel
  from '@/vue-app/view-models/components/recovery-password/successfully-recovered-view-model';

@Component({ name: 'SuccessfullyPasswordRecovered' })
export default class RecoveryPassword extends Vue {
  successfully_recovered_view_model = Vue.observable(new SuccessfullyRecoveredViewModel());
}

