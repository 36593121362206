import i18n from '@/vue-app/plugins/i18n';

import TYPES from '@/types';

// Domain
import { Router } from '@/modules/shared/domain/router';
import Inject from '@/modules/shared/domain/di/inject';

export default class SuccessfullyRecoveredViewModel {
  @Inject(TYPES.ROUTER)
  private readonly router!: Router;

  private readonly i18n_namespace = 'successfully_password_recovered';

  translate = (identifier: string) => (
    i18n.t(`${this.i18n_namespace}.${identifier}`)
  )

  accept = () => {
    this.router.navigate('login');
  }
}
